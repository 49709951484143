import React from "react";
import "./style/chat-container.scss";
import { ApiEngine } from "api-engine";
import MessageContainer from "./message-container/common/MessageContainer";
import MessageContainerSkeleton from "./message-container/common/MessageContainerSkeleton";

interface ChatContainerProps {
    chat: any;
    api: ApiEngine;
}

interface ChatContainerState {
    messages: any[];
    messagesLoading: boolean;
    message: any;
}

const messagesMock = [
    {
        id: "12123132",
        content: "Привет, как дела?",
        author: "John Doe",
        authorType: "user",
        userId: "12123132",
        partnerId: "12123136",
        createdAt: new Date(),
    },
    {
        id: "12123136",
        content: "Привет, все хорошо, спасибо!",
        author: "Jane Doe",
        authorType: "user",
        userId: "12123136",
        partnerId: "12123132",
        createdAt: new Date(),
    }
]

export default class ChatContainer extends React.Component<ChatContainerProps, ChatContainerState> {
    messagesPollingTimeout: any
    messagesContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    constructor(props: ChatContainerProps) {
        super(props);
        this.state = {
            messages: [],
            messagesLoading: true,
            message: "",
        };
        this.getMessages = this.getMessages.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage() {
        const me = this;
        const url = `/api/dialogs/${me.props.chat.id}/messages`;
        const body = me.state.message
        if (!body.content) return;
        me.props.api.asyncFetch(url, {
            method: "POST",
            body: JSON.stringify(body)
        }).then((res) => {
            me.setState({message: {content: ""}}, () => {
                me.getMessages();
            });
        });
    }

    componentDidMount() {
        const me = this;
        me.getMessages();
    }

    componentWillUnmount() {
        if (this.messagesPollingTimeout) clearTimeout(this.messagesPollingTimeout);
    }

    getMessages() {
        if (this.messagesPollingTimeout) clearTimeout(this.messagesPollingTimeout);
        const me = this;
        const url = `/api/dialogs/${me.props.chat.id}/messages`;
        me.props.api.asyncFetch(url, {}).then((res) => {
            let messages = res.dialogMessages ? res.dialogMessages : ([] as any[]);
                if (me.state.messages.length != messages.length) {
                    let messagesToAdd = messages.reverse();
                    messagesToAdd.forEach((message: any) => {
                        message.createdAt = (new Date(message.createdAt)).toLocaleString();
                    });
                    me.setState({messages: messagesToAdd, messagesLoading: false}, () => {
                        me.messagesContainerRef.current?.scrollTo({
                            top: me.messagesContainerRef.current?.scrollHeight,
                            behavior: 'smooth'
                        });
                        me.messagesPollingTimeout = setTimeout(() => {
                            me.getMessages();
                        }, 10000);
                    });
                } else {
                    me.setState({messagesLoading: false}, () => {
                        me.messagesPollingTimeout = setTimeout(() => {
                            me.getMessages();
                        }, 10000);
                    });
                }
                
        });
    }

    render() {
        const me = this;
        return <div className={"chat-container"} onKeyUp={(e) => {
            if (e.key == "Enter") me.sendMessage();
        }}>
            <h3 className={"title"}>{me.props.chat.title}</h3>
            <div className={"messages-container"} ref={me.messagesContainerRef}>
                {me.state.messagesLoading ? [1,2,3,4].map((item) => {
                    return <MessageContainerSkeleton key={item} />
                })
                :
                me.state.messages.map((message, index) => (
                    <MessageContainer key={index} message={message} />
                ))}
                { !me.state.messagesLoading && (me.state.messages.length == 0) &&
                    <p className={"no-messages"}>Сообщений пока нет</p>
                }
            </div>
            <div className={"message-input-container"}>
                <input type="text" 
                    value={me.state.message ? me.state.message.content : ""}
                    placeholder="Введите сообщение" 
                    onChange={(e) => me.setState({message: {content: e.target.value}})} />
                
                <i 
                    onClick={() => me.sendMessage()}
                    className={`fas fa-paper-plane ${me.state.message.content ? "active" : ""}`}></i>
            </div>
        </div>;
    }
}



