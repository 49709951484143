import React from "react";
import {ApiEngine} from "api-engine";
import "./style/chat-vertical-bar.scss";
import ChatOpeningButton from "../components/chat-opening-button/ChatOpeningButton";
import ChatOpeningButtonSkeleton from "../components/chat-opening-button/ChatOpeningButtonSkeleton";
import ChatContainer from "../components/chat-container/common/ChatContainer";
import ModalWindow from "../../../../layout/modal/ModalWindow";

interface ChatVerticalBarProps {
    api: ApiEngine
    user: any
}

interface ChatVerticalBarState {
    listOfChats: any[]
    listOfChatsLoading: boolean
    activeChat: any
    isTouch: boolean
}

const listOfChatsMock = [
    {
        id: 1,
        title: "Chat 1",
        amount: 10,
        fontAwesomeIcon: "fa-solid fa-user"
    },
    {
        id: 2,
        title: "Chat 2",
        amount: 2,
        fontAwesomeIcon: "fa-solid fa-car"
    },
]

export default class ChatVerticalBar extends React.Component<ChatVerticalBarProps, ChatVerticalBarState> {
    chatsUpdateTimeout: any = null;
    constructor(props: ChatVerticalBarProps) {
        super(props);
        this.state = {
            listOfChats: [],
            listOfChatsLoading: true,
            activeChat: null,
            isTouch: false
        };

        this.getListOfChats = this.getListOfChats.bind(this);
        this.openChat = this.openChat.bind(this);
    }   

    componentDidMount() {
        this.getListOfChats();
    }

    componentWillUnmount() {
        clearTimeout(this.chatsUpdateTimeout);
    }   

    getListOfChats() {
        const me = this;
        // setTimeout(() => {
        //     me.setState({listOfChats: listOfChatsMock, listOfChatsLoading: false});
        // }, 2000);
        // return
        const url = "/api/dialogs/";
        this.props.api.asyncFetch(url, {}).then((response) => {
            const dialogs = response.dialogs ? response.dialogs : [];
            me.setState({listOfChats: dialogs, listOfChatsLoading: false}, () => {
                me.chatsUpdateTimeout = setTimeout(() => {
                    me.getListOfChats();
                }, 10000);
            });
        });
    }

    openChat(chat: any) {
        const me = this;
        me.setState({activeChat: chat});
    }

    render() {
        const me = this;
        if (me.state.activeChat && window.innerWidth > 500 && !me.state.isTouch) {
            return <div className={"chat-vertical-bar-with-active-chat"}>
                <div className={"chat-closing-button"} onClick={() => me.openChat(null)}>
                    <i className="fal fa-times-circle"></i>
                    <p>Закрыть</p>
                </div>
                <ChatContainer chat={me.state.activeChat} api={me.props.api} />
            </div>
        }
        return <div className={"chat-vertical-bar"}>
            {
                me.state.listOfChatsLoading ? 
                [1,2,3].map((item) => {
                    return <div key={item} className={"chat-vertical-bar__item"}>
                        <ChatOpeningButtonSkeleton />
                    </div>
                })
                :
                me.state.listOfChats.map((chat, chatIndex) => {
                    return <div key={chat.id} className={"chat-vertical-bar__item"}>
                        <ChatOpeningButton api={me.props.api} chat={chat}
                                           onTouch={() => me.setState({
                                               isTouch: true
                                           }, () => {
                                               me.openChat(chat.id)
                                           }) }
                                           onClick={() => me.openChat(chat)}/>
                    </div>
                })
            }

            { me.state.activeChat &&
                <ModalWindow onClick={() => {me.openChat(null)}}>
                    <ChatContainer chat={me.state.activeChat} api={me.props.api} />
                </ModalWindow>
            }

        </div>;
    }
}




