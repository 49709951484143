import React from 'react';
import "../styles/total_style.scss";
import SideMenu from "./layout/side_menu/common/SideMenu";
import Main from "./layout/main/common/Main";
import ApiProvider from "./domains/api/providers/ApiProvider";
import News from "./layout/header/news/common/News";
import {ApiContext} from "./domains/api/contexts/ApiContext";
import LoginWindow from "./domains/login/LoginWindow";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Router from "react-router-dom";
import Demo from "./pages/demo/Demo";
import Search from "./components/searches/Search";
import ChatVerticalBar from "./domains/chat/layout/chat_vertical_bar/ChatVerticalBar";

const router = createBrowserRouter([
    {
        path: `/*`,
        index: true,
        element: <Main />
    }

], {basename: process.env["PUBLIC_URL"]});

export default class App extends React.Component<any, any>{
  constructor(_props: any) {
    super(_props);
    this.state = {
      user: null
    }
  }
  render() {
    return <div className={"application"} data-testid={"application-container"}>
            <ApiProvider>
              <ApiContext.Consumer>
                {
                  ({api, user}) => {
                    if (user) return <SideMenu user={user} api={api!!}/>
                    return <></>
                  }
                }
              </ApiContext.Consumer>
            </ApiProvider>
            <RouterProvider router={router} />
            <ApiProvider>
                <ApiContext.Consumer>
                    {
                        ({api, user}) => {
                            if (user) return <ChatVerticalBar user={user} api={api!!}/>
                            return <></>
                        }
                    }
                </ApiContext.Consumer>
            </ApiProvider>
            <ApiProvider>
              <ApiContext.Consumer>
                {
                  ({api, user}) => {
                    if (user) return <></>
                    return <LoginWindow api={api!!} />
                  }
                }
              </ApiContext.Consumer>
            </ApiProvider>
          </div>

  }
}
