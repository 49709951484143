import React from "react";
import "./style/chat-opening-button.scss";

export default class ChatOpeningButtonSkeleton extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            chatIsOpened: false
        };
    }

    render() {
        return <div className={"chat-opening-button-container"}>
            <div className={"chat-opening-button-skeleton"}>
            </div>
        </div>
    }
}
