import React from "react";
import "./style/menu-button.scss";

interface MenuButtonProps {
  content: any;
  showTitle: boolean;
}
interface MenuButtonState {
}

export default class MenuButton extends React.Component<MenuButtonProps, MenuButtonState> {
  render() {
    const me = this;
    return <div className={"menu-button"} onClick={() => {
      if (me.props.content.href) {
        if (me.props.content.confirm) {
          const confirm = window.confirm(me.props.content.confirm);
          if (!confirm) {return}
        }
        document.location.href = me.props.content.href;
      }
    }}>
      <i className={me.props.content.faIcon}/>
      { me.props.showTitle &&
        <span className={"title"}>{me.props.content.title}</span>
      }
      <span className={me.props.showTitle ? "amount" : "amount-white"}>{me.props.content.amount}</span>
    </div>;
  }
}